// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Caret from "https://framerusercontent.com/modules/Axpol70OdRugqukuVIqY/fRCYwLdA8hEctMwccGMQ/IGFqizJ7S.js";
const CaretFonts = getFonts(Caret);

const cycleOrder = ["zbxnSfLfu", "xkvEGQDpQ"];

const serializationHash = "framer-gTD1Y"

const variantClassNames = {xkvEGQDpQ: "framer-v-xtddas", zbxnSfLfu: "framer-v-z14tzi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "zbxnSfLfu", Open: "xkvEGQDpQ"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "Product", PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zbxnSfLfu"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zbxnSfLfu", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1nzhzpa = activeVariantCallback(async (...args) => {
if (PWSlWeqS6) {
const res = await PWSlWeqS6(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-z14tzi", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"zbxnSfLfu"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1nzhzpa} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(0, 0, 0)", ...style}} {...addPropertyOverrides({xkvEGQDpQ: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Um9ib3RvLTUwMA==", "--framer-font-family": "\"Roboto\", \"Roboto Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Product</motion.p></React.Fragment>} className={"framer-1jj0i5z"} fonts={["GF;Roboto-500"]} layoutDependency={layoutDependency} layoutId={"LRIvMbU7p"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px", opacity: 1}} text={DApvjrdfG} variants={{xkvEGQDpQ: {opacity: 0.5}}} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-cjbh4g-container"} layoutDependency={layoutDependency} layoutId={"ZP4gGZb75-container"}><Caret height={"100%"} id={"ZP4gGZb75"} layoutId={"ZP4gGZb75"} variant={"OVKxmcYcs"} width={"100%"} {...addPropertyOverrides({xkvEGQDpQ: {variant: "W1rfza3wq"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gTD1Y.framer-1vjwrww, .framer-gTD1Y .framer-1vjwrww { display: block; }", ".framer-gTD1Y.framer-z14tzi { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-gTD1Y .framer-1jj0i5z { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-gTD1Y .framer-cjbh4g-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gTD1Y.framer-z14tzi { gap: 0px; } .framer-gTD1Y.framer-z14tzi > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-gTD1Y.framer-z14tzi > :first-child { margin-left: 0px; } .framer-gTD1Y.framer-z14tzi > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 88
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"xkvEGQDpQ":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKX_hXU8XH: React.ComponentType<Props> = withCSS(Component, css, "framer-gTD1Y") as typeof Component;
export default FramerKX_hXU8XH;

FramerKX_hXU8XH.displayName = "Trigger Copy 8";

FramerKX_hXU8XH.defaultProps = {height: 50, width: 88};

addPropertyControls(FramerKX_hXU8XH, {variant: {options: ["zbxnSfLfu", "xkvEGQDpQ"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "Product", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerKX_hXU8XH, [{explicitInter: true, fonts: [{family: "Roboto", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vBh05IsDqlA.woff2", weight: "500"}]}, ...CaretFonts], {supportsExplicitInterCodegen: true})